import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";

//components
import Button from 'react-bootstrap/Button';
import Spinner from "../../../components/Spinner/Spinner";
import { validateEmail } from "../../../dist/js/validateEmail";
import MyModal from '../../../components/MyModal'

//css
import '../../../dist/css/reservation.css'

// Redux
import { useDispatch, useSelector } from "react-redux";
import { addGroupOrder } from "../../../redux/groupOrders/group-orders-action"

const FormSummary = (props) => {
    // console.log('roductsLogs', props.productsDetails)
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = React.useState(false);
    const [errorModalShow, setErrorModalShow] = React.useState(false);
    const [quantities, setQuantities] = useState([])
    const [loadProducts, setLoadProducts] = useState([])
    const [productsMessage, setProductsMessage] = useState('')
    const [displaySpinner, setDisplaySpinner] = useState('none')

    let { deliveryId, groupCode, isAdminForm } = useParams();
    const history = useHistory()

    useEffect(() => {

        setQuantities(props.productsDetails.quantity)
        setLoadProducts(props.productsDetails.products)

        // console.log(' props.inputTexts.paymentChecked', props.inputTexts.paymentChecked)

        //change the style of table of chosen products based on screen 
        try {
            if (window.screen.width < 800) {
                document.getElementById("productsTable").classList.remove("table");
            }
            else {
                document.getElementById("productsTable").classList.add("table");
            }
        } catch (error) {

        }

    }, [props.productsDetails.quantity])

    const postReservation = () => {

        const { groupName, deliveryDateTime, payBoxLink, deliveryAddress, firstName, lastName,
            email, phone, userNote, paymentChecked, place, deliveryPlaces } = props.inputTexts

        let myArray = []
        let myObj = {}

        props.productsDetails.quantity.map((qnt, index) => {
            props.productsDetails.products.map(products => {
                if (qnt > 0 && products.Code === index + 1) {
                    myArray.push(
                        myObj = {
                            "Code": index + 1,
                            "Name": products.Name,
                            "Price": products.Price,
                            "Weight": products.Weight,
                            "Amount": qnt
                        }
                    )

                }
            })
        })

        // console.log('deliveryId', deliveryId)
        const objectPost =

        {
            "GroupName": groupName,
            "DeliveryPlaces": deliveryPlaces,
            "DateOfDelivery": new Date(deliveryDateTime),
            "payBoxLink": payBoxLink,
            // "AddressOfDelivery": deliveryAddress,
            "Place": place,
            "GroupCode": groupCode,
            "GroupDeliveryId": deliveryId,
            "PersonInfo": {
                "FirstName": firstName,
                "LastName": lastName,
                "Email": email,
                "Phone": phone,
                "UserId": null
            },
            "Products": myArray,
            "TotalPrice": props.productsDetails.totalPrice,
            "Comment": userNote,
            "PaymentMethod": paymentChecked
        }

        // console.log('objectPost', objectPost)
        // console.log('deliveryIdFromCLinet', this.state.deliveryIdFromCLinet)
        setDisplaySpinner('block');

        debouncePostReservation(objectPost);

    }

    //move to next page after 1 second
    const debouncePostReservation = _.debounce((objectPost) => {

        addGroupOrder(objectPost).then((data) => {
            objectPost.orderNumber = data.payload[0].OrderNumber
            localStorage.setItem('lastReservationOfUser', JSON.stringify(objectPost));

            dispatch(data);
            if (isAdminForm == 1)
                history.push(`/admin/deliveries/groups-orders/${groupCode}/${deliveryId}`)
            else
                history.push(`/groups/${groupCode}/${deliveryId}/${data.payload[0].OrderNumber}/thanks`)
        }).catch(() => {
            setDisplaySpinner('none');
            alert('אירעה שגיאה')
            setDisplaySpinner('none');
        })

    }, 1000);

    const errorOfSubmit = () => {

        const { firstName, lastName, email, phone, paymentChecked, place, approvedPayboxPayment } = props.inputTexts
        // console.log('approvedPayboxPayment', approvedPayboxPayment)
        // console.log('paymentChecked', paymentChecked)
        let nameOfElement = ''
        if (firstName === '')
            nameOfElement = 'firstName'
        else if (lastName === '')
            nameOfElement = 'lastName'
        else if (email === '')
            nameOfElement = 'email'
        else if (phone === '')
            nameOfElement = 'phone'
        else if (place === '') {
            // alert('in place')
            nameOfElement = 'divOfInputPlaces'
            document.getElementById("divOfInputPlaces").classList.add('divOfInputPlacesClass')
        }
        else if (paymentChecked === '') {
            nameOfElement = 'divOfInputPayment'
            document.getElementById("divOfInputPayment").classList.add('divOfInputPaymentClass')
        }
        else if (!approvedPayboxPayment) {
            nameOfElement = 'divOfInputPayment'
            document.getElementById("divOfInputPayment").classList.add('divOfInputPaymentClass')
        }
        // console.log('nameOfElement', nameOfElement)
        try {
            let myElement = document.getElementById(nameOfElement)
            myElement.scrollIntoView();
            myElement.focus();
            setErrorModalShow(true)
        } catch (error) {
            {
                // props.productsDetails.totalPrice < props.minimumTotalPrice ?
                //     setProductsMessage(`אנא בחר/י מוצרים בשווי מינימילי בסך ${props.minimumTotalPrice} ₪`)
                //      : setProductsMessage('אנא בחר/י מוצר אחד לפחות')

                if (props.productsDetails.totalPrice < props.minimumTotalPrice)
                    setProductsMessage(`אנא בחר/י מוצרים בשווי מינימילי בסך ${props.minimumTotalPrice} ₪`)
                else {
                    //blocked users
                    if (
                        (phone === '0524482704' || email === 'ideadorit@gmail.com') ||
                        (phone === '0542621280' || email === 'yaelgivatiilan@gmail.com') ||
                        (phone === '0544234353' || email === 'lindarennert@yahoo.com') ||
                        (phone === '0507379439' || email === 'galit.sh100@gmail.com') ||
                        (phone === '0546472627' || email === 'itamar346@gmail.com')
                    ) {
                        setProductsMessage("הזמנה זו לא מאושרת. משתמש זה נחסם אוטומטית עקב היסטוריית ביטולי הזמנות")
                    }
                    else if (email === "iritu@gmail.com") {
                        setProductsMessage("כתובת דואר אלקטרונית שמולאה אינה קיימת")
                    }
                    else {
                        let myElement = document.getElementById('email')
                        myElement.scrollIntoView();
                        myElement.focus();
                        props.setDisplayEmailError('block')
                    }

                }

            }
        }
        if (document.getElementById('formHeadline') != null)
            document.getElementById('formHeadline').innerText = 'אנא מלא/י את כל שדות החובה'

        document.getElementById('formSecondHeadline').innerText = 'אנא מלא/י את כל שדות החובה'

    }



    // function validateEmail(email) {
    //     const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    //     // console.log('validate Email', re.test(String(email).toLowerCase()))
    //     const result = re.test(String(email).toLowerCase());

    //     if (result)
    //         return true
    //     else {
    //         return false
    //     }

    // }
    const handleSubmit = () => {
        //admin back office form
        if (isAdminForm == 1) {
            props.inputTexts.firstName !== ''
                && props.inputTexts.lastName !== ''
                && props.inputTexts.paymentChecked !== ''
                && props.inputTexts.approvedPayboxPayment
                && props.inputTexts.place != '' ?
                postReservation()
                : errorOfSubmit()
        }
        //public form
        else {
            props.inputTexts.firstName !== ''
                && props.inputTexts.lastName !== ''
                && props.inputTexts.email !== ''
                && props.inputTexts.phone !== ''
                && props.inputTexts.paymentChecked !== ''
                && props.inputTexts.approvedPayboxPayment
                && props.inputTexts.place != ''
                && props.productsDetails.totalPrice >= props.minimumTotalPrice
                && validateEmail(props.inputTexts.email)
                && props.inputTexts.phone !== '0524482704' && props.inputTexts.email !== 'ideadorit@gmail.com'
                && props.inputTexts.phone !== '0542621280' && props.inputTexts.email !== 'yaelgivatiilan@gmail.com'
                && props.inputTexts.phone !== '0544234353' && props.inputTexts.email !== 'lindarennert@yahoo.com'
                && props.inputTexts.phone !== '0507379439' && props.inputTexts.email !== 'galit.sh100@gmail.com'
                && props.inputTexts.phone !== '0546472627' && props.inputTexts.email !== 'itamar346@gmail.com'
                && props.inputTexts.email !== "iritu@gmail.com" ?
                // onClick={() => props.productsDetails.totalPrice > 0 ?
                // setModalShow(true)
                postReservation()
                : errorOfSubmit()
        }

    }

    return (
        <MDBContainer id='formContainer' className=''>
            <MDBRow>
                <MDBCol md="12" style={{ margin: 'auto' }}>
                    {/* <form> */}
                    <div className="black-text">
                        <p className="text-center text-danger">{productsMessage}</p>
                    </div>
                    {
                        props.footer !== undefined && props.footer !== "undefined"
                            && props.footer !== null && props.footer !== "null" && props.footer.length > 0 ?
                            <div className="col-12 text-center" id="headerOfForm">
                                {props.footer}
                                < hr />
                            </div>
                            : ''
                    }

                    <MDBContainer>
                        <MDBModal isOpen={modalShow}>
                            <h3 className="text-center">טוען...</h3>
                            <MDBModalFooter>
                                <div className="spinner-border" role="status" >
                                </div>
                            </MDBModalFooter>
                        </MDBModal>
                    </MDBContainer>

                    {props.errorPhone != "" && <p className="text-danger text-center">{props.errorPhone}</p>}

                    <div>
                        <div className="text-center">

                            <Button variant="primary" type="" value="Send" disabled={(displaySpinner === 'block' ? 'disabled' : '') || (props.errorPhone != "" ? "disabled" : "")}
                                onClick={handleSubmit
                                }
                                className='submitBtn'>
                                שליחה <MDBIcon far icon="paper-plane" className="ml-1" />
                            </Button>

                        </div>

                        <Spinner
                            display={displaySpinner}
                        />

                    </div>
                    {/* </form> */}
                </MDBCol>
            </MDBRow>
            <MyModal
                headlineText='אנא מלא/י את כל שדות החובה!'
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
                ButtonDinamicFunction={() => setErrorModalShow(false)}
                ButtonCloseText={false}
                ButtonDeleteText="הבנתי"
                ButtonText=''
            />
        </MDBContainer >
    );
};

export default FormSummary;