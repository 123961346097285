import React, { PureComponent } from 'react'

import { MDBCol, MDBCollapse, MDBInput } from 'mdbreact'

//components
import FormSummary from './FormSummary'
import ImageModal from '../../../components/ImageModal/ImageModal'
import MyModal from '../../../components/MyModal'

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getProducts, getProductByCode } from '../../../redux/products/products-actions';

//css
import '../../../dist/css/reservation.css'

import parse from 'html-react-parser';

class FormProducts extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      quantity: [],
      peoductChosen: [],
      totalPrice: '0',
      products: [],
      InActiveProducts: [],
      productsFromDelivery: [],
      collapseID: "",
      showModal: false,
      productName: "",
      modalText: ""

    }
  }

  handleClick = (event) => {

    //catch the amount
    const pricePlus = event.target.name

    // document.getElementById('trashBtn' + event.target.id).style.display = 'inline-block'
    document.getElementById('trashBtn' + event.target.id).classList.add('activeTrashBtn')

    //shadow the chosen row
    document.getElementById('productRow' + event.target.id).classList.add('activeProduct')

    //add 1 to the amount
    document.getElementById('number' + event.target.id).value++

    //catch the place of the qunatity of the product (1 / 2 / 3.....)
    let place = event.target.id

    // update array of qunatities
    let myDinamicArray = this.state.quantity

    //if peppers - change count to 2
    if (parseInt(place) === 10) {
      myDinamicArray[place - 1] += 2
      document.getElementById('number' + event.target.id).value++
    }
    else {
      myDinamicArray[place - 1] += 1
    }

    //updating sate
    this.setState(prevState => (
      {
        totalPrice: parseInt(prevState.totalPrice) + parseInt(pricePlus),
        quantity: myDinamicArray
      }
    ));

  }

  handleClickMinus = (event) => {

    //if the amount is bigger than 0, go into the function
    if (document.getElementById('number' + event.target.id).value > 0) {

      //catch the price of product
      const priceMinus = event.target.name

      //if product amount is bigger than 0, remove it by one
      if (document.getElementById('number' + event.target.id).value > 0) {
        document.getElementById('number' + event.target.id).value--

        //catch the place of the qunatity of the product (1 / 2 / 3.....)
        let place = event.target.id

        // update array of qunatities
        let myDinamicArray = this.state.quantity

        //if peppers - change count to 2
        if (parseInt(place) === 10) {
          myDinamicArray[place - 1] -= 2
          document.getElementById('number' + event.target.id).value--
        }
        //if other veg - change count to 1
        else {
          myDinamicArray[place - 1] -= 1
        }

        //updating state if total price is bigger than 0
        if (this.state.totalPrice > 0) {
          this.setState(prevState => (
            {
              totalPrice: parseInt(prevState.totalPrice) - parseInt(priceMinus),
              quantity: myDinamicArray

            }
          ));
        }

      }

      //if product amount is smaller than 1, remove the row's shadow and the trash
      if (document.getElementById('number' + event.target.id).value < 1) {
        document.getElementById('productRow' + event.target.id).classList.remove('activeProduct')
        document.getElementById('trashBtn' + event.target.id).classList.remove('activeTrashBtn')
      }

    }
  }
  handleCancel = (event) => {

    //catch the price of product
    const priceCancel = event.target.name

    //remove the trash and the row's shadow
    try {
      document.getElementById('trashBtn' + event.target.id).classList.remove('activeTrashBtn')
      document.getElementById('productRow' + event.target.id).classList.remove('activeProduct')
    } catch (error) {

    }

    //catch the place of the qunatity of the product (1 / 2 / 3.....)
    let place = event.target.id

    //catch the value from the amount of the product
    let myValue = null

    //if peppers - delete half of calculated amount
    if (parseInt(place) === 10) {
      myValue = document.getElementById('number' + event.target.id).value / 2
    }
    else {
      try {
        myValue = document.getElementById('number' + event.target.id).value

      } catch (error) {
        myValue = 0
      }
    }

    // update array of qunatities
    let myDinamicArray = this.state.quantity
    myDinamicArray[place - 1] = 0

    //update state:
    // 1. totalPrice to - (totalPrice - amount of the product()
    // 2. quantity in the place of current product to -(0)
    this.setState(prevState => (
      {
        totalPrice: parseInt(prevState.totalPrice) - (parseInt(priceCancel) * myValue),
        quantity: myDinamicArray
      }
    ));

    //update the amount of the quantity of the product to 0
    try {
      document.getElementById('number' + place).value = 0
    } catch (error) {

    }
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }

  loadMyProducts = () => this.state.products.map((product, index) => {

    // console.log('product:', product)

    // let myImage = product.ReservationImage
    let myImage = product.ReservationImage.toString()

    //set product prices by qunatitiy selected
    let prodprice = product.Price
    let prodSumPrice = (product.Price * (this.state.quantity[product.Code - 1]))

    if (product.Code == 10) //if it's peppers set the price different
      prodSumPrice > 0 ? prodprice = (prodSumPrice / 2) : prodprice = product.Price
    else
      prodSumPrice > 0 ? prodprice = prodSumPrice : prodprice = product.Price

    //set price size by qunatitiy selected
    let fontSize = 'larger'

    if (this.state.quantity[product.Code - 1] > 0) {
      fontSize = "x-large"
    }

    let display = 'none'

    if (product.InStock) {
      // typeOfProduct = 'invalidProduct'
      // prodprice = 'לא זמין במלאי'

      this.state.productsFromDelivery.map(deliveryProduct => {
        // console.log('deliveryProduct', deliveryProduct)
        if (deliveryProduct.Code === product.Code && deliveryProduct.IsActive) {
          display = 'block'
        }

      })

      return (
        <div id={'productRow' + product.Code} key={product.Code} style={{ display: display }}>

          {/* PRODUCT */}
          <div className="text-center row myProduct">
            <div className="col-lg-2 col-md-12 col-sm-12">

              <a className="lightbox" href={`#myImage${product.Code}`}>
                <img className="img-responsive" src={this.props.isAdminForm ? `../../../../../../${myImage}` : `../${myImage}`} alt="prewiew" width={120} height={80}></img>
              </a>

              <ImageModal
                imageNumber={`myImage${product.Code}`}
                imageSrc={`../${myImage}`}
                closedHref={'#productRow' + (product.Code - 1)}
              />

            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 text-md-center">
              <h4 id='prodName' className="product-name"><b>{product.Name}</b></h4>

              <h4>
                <small onClick={this.toggleCollapse(product.Code)} style={{ cursor: 'pointer' }}>{product.DescriptionForRes}&nbsp;
                  {
                    product.Code === 11 ?
                      <>
                        <br />
                        <small> (במידה ויהיה מחסור בפורטבלו יסופקו זוג מארזי שמפיניון)</small>&nbsp;
                      </>
                      : product.Code === 5 ?
                        <>
                          <br />
                          <small> (במידה ויהיה מחסור בשרי כתום יסופק שרי צהוב)</small>&nbsp;
                        </>
                        : ""
                  }
                  <i className="fa fa-angle-down rotate-icon" ></i>
                </small>
                <>
                  <MDBCollapse id={product.Code} isOpen={this.state.collapseID}>
                    <small className="font-italic" style={{ fontSize: 'medium' }}>{product.Description}</small>
                  </MDBCollapse>
                </>
              </h4>

            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 text-sm-center text-md-right row">

              <div className="propertyOfProduct">
                <h6 className="animated fadeIn">
                  {
                    prodprice !== product.Price ?
                      <strong className="font-italic font-weight-bold animated fadeInRight" style={{ fontSize }}>
                        {prodprice} ₪
                      </strong>
                      : <strong className="font-italic font-weight-bold animated fadeInLeft " style={{ fontSize }}>
                        {prodprice} ₪
                      </strong>
                  }
                </h6>
              </div>

              {product.InStock ?
                <div className="col-lg-8 col-md-12 col-sm-12 text-md-center quantityContainer" style={{ margin: 'auto', zIndex: 0 }}>
                  <div className="quantity col-lg-12 col-sm-12 text-md-center">

                    <button className="rounded-button fa fa-plus"
                      onClick={this.handleClick} name={product.Price} id={product.Code}
                    >
                    </button>

                    <input className='propertyOfProduct qty' type="number" step={1} max={99} min={0} id={'number' + product.Code}
                      style={{ fontSize: fontSize }}
                      value={this.state.quantity[product.Code - 1]} title="Qty" name={product.Code} disabled />

                    <button className="rounded-button fas fa-minus"
                      onClick={this.handleClickMinus} name={product.Price} id={product.Code}
                    >
                    </button>

                  </div>
                </div>
                : ''
              }
              <div className="propertyOfProduct col-lg-2 col-md-12 col-sm-12 text-center"
                id={'trashBtn' + product.Code} name={product.Price}
                style={{ visibility: 'hidden' }}
              >
                <button type="button" className="animated fadeInRight btn btn-outline-danger btn-xs fa fa-trash dinamicTrashBtn"
                  name={product.Price} onClick={this.handleCancel} id={product.Code}>
                  <i className="fa fa-trash fa-2x myFaFaTrash" aria-hidden="true" style={{ pointerEvents: 'none', margin: 'auto' }} />
                </button>
              </div>

            </div>
          </div>
          <hr />
          {/* END PRODUCT */}
        </div >
      )
    }
  });

  componentDidUpdate(prevProps) {

    if (this.props.lastReservationProducts != prevProps.lastReservationProducts) {

      //only if user has last reservation storage on browser
      if (this.props.lastReservationProducts != null) {

        let myQuantities = this.state.quantity
        let totalPriceFromLastRes = 0

        for (let index = 0; index < this.props.lastReservationProducts.length; index++) {
          let codeOfProduct = this.props.lastReservationProducts[index].Code;

          //only if Product isActive - add it to the current quantities
          this.state.productsFromDelivery.map(deliveryProduct => {
            if (deliveryProduct.Code === codeOfProduct && deliveryProduct.IsActive) {
              myQuantities.splice(codeOfProduct - 1, 1, this.props.lastReservationProducts[index].Amount);

              //only if Product InStock - add it to the current totalPrice
              this.state.products.map(product => {
                if (product.Code === codeOfProduct && product.InStock) {

                  if (product.Code == 10)
                    totalPriceFromLastRes += (this.props.lastReservationProducts[index].Amount * (product.Price / 2))
                  else
                    totalPriceFromLastRes += (this.props.lastReservationProducts[index].Amount * product.Price)

                  //shadow the trash button
                  document.getElementById('trashBtn' + codeOfProduct).classList.add('activeTrashBtn')
                  //shadow the chosen product row
                  document.getElementById('productRow' + codeOfProduct).classList.add('activeProduct')
                  //update the amount of product
                  document.getElementById('number' + codeOfProduct).value = this.props.lastReservationProducts[index].Amount
                }
              })
            }
            else if (deliveryProduct.Code === codeOfProduct && !deliveryProduct.IsActive) {
              console.log('INN', this.props.lastReservationProducts[index].Name);
              // alert(` לתשומת ליבך, בהזמנתך הקודמת הוזמן המוצר "${this.props.lastReservationProducts[index].Name}" שאינו במלאי בטופס זה`)

              this.setState(prevState => (
                {
                  showModal: true,
                  productName: prevState.productName == "" ? this.props.lastReservationProducts[index].Name : `${prevState.productName}, ${this.props.lastReservationProducts[index].Name}`,
                  modalText: prevState.modalText != "" ?
                    parse(`לתשומת ליבך, בהזמנתך הקודמת הוזמנו המוצרים "<b style="font-weight: bold;">${prevState.productName}, ${this.props.lastReservationProducts[index].Name}</b>" שאינם קיימים במלאי השבוע`)
                    : parse(`לתשומת ליבך, בהזמנתך הקודמת הוזמן המוצר "<b style="font-weight: bold;">${this.props.lastReservationProducts[index].Name}</b>" שאינו קיים במלאי השבוע`)
                }
              ));

            }

          })

        }

        this.setState({
          quantity: myQuantities,
          totalPrice: Number(this.state.totalPrice) + Number(totalPriceFromLastRes)
        })

      }
    }

  }

  componentDidMount() {


    let myArrayForQunatities = []

    this.props.getProducts()
      .then(() => {
        this.setState({
          products: this.props.products,
          // InActiveProducts: this.props.InActiveProductsFromDelivery,
          productsFromDelivery: this.props.productsFromDelivery
        })

        for (let index = 0; index < this.props.products.length; index++) {
          myArrayForQunatities.push(0)
        }
        // console.log('InActiveProductsFromDelivery:', this.props.InActiveProductsFromDelivery)
        this.setState({
          quantity: [...myArrayForQunatities],
        })
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const passState = this.state
    const passProps = this.props
    return (
      <div>
        <div className="card-body">
          {this.loadMyProducts()}
        </div>
        <MDBCol size="12" md="8" style={{ margin: 'auto' }} className='comment-section'>
          <MDBInput type="textarea" rows="3" label="הערות" icon="pencil-alt"
            value={this.props.userNote} onChange={e => this.props.setUserNote(e.target.value)} name='user_notes' />
        </MDBCol>

        <div className="card-header bg-dark text-light second-template" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {/* סיכום */}
          <h5 className='col-sm-12 text-center m-0' style={{ fontWeight: "bold" }}>סה"כ סכום ההזמנה:&nbsp;
            <b>{this.state.totalPrice} ₪</b>
          </h5>
          &nbsp;
          <div className="clearfix" />
        </div>
        <FormSummary
          groupName={this.props.groupName}
          deliveryDateTime={this.props.deliveryDateTime}
          deliveryAddress={this.props.deliveryAddress}
          minimumTotalPrice={this.props.minimumTotalPrice}
          productsDetails={passState}
          inputTexts={passProps}
          footer={this.props.footer}
          setDisplayEmailError={this.props.setDisplayEmailError}
          errorPhone={this.props.errorPhone}
        />
        <MyModal
          headlineText={this.state.modalText}
          show={this.state.showModal}
          onHide={() => {
            this.setState({
              showModal: false,
              productName: ""
            })
          }}
          ButtonText='סגירה'
        // ButtonDeleteText='אישור'
        // ButtonDinamicFunction={() => poastUser()}
        />
      </div>
    )
  }

}
const mapStateToProps = (state) => {
  return {
    products: state.productReducer.products,
    product: state.productReducer.product
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProducts,
      getProductByCode
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(FormProducts);