import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBCardImage, MDBInput } from 'mdbreact';
import UpdateOrderProducts from './UpdateOrderProducts'
import GetLocalDayAndHour from '../../../../components/GetLocalDayAndHour'
import GedDayOfWeek from '../../../../components/GedDayOfWeek'
import parse from 'html-react-parser';
import { MDBAnimation } from "mdbreact";
import moment from 'moment-timezone'

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GetGroupDeliveryById } from "../../../../redux/deliveries/deliveries-actions";
import { getGroupByCode } from "../../../../redux/groups/groups-actions";
import { getGroupDeliveryOrderByGroupDeliveryIdAndOrderNumber, updateGroupOrderPaidStatus } from "../../../../redux/groupOrders/group-orders-action";
import { validatePhone, validateHebrew } from '../../../../utils/validations';

import '../../form/form.scss'

class UserUpdateOrder extends React.Component {
    constructor() {
        super()
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            comment: '',
            internalComments: '',
            paymentChecked: '',
            groups: [],
            isLoadingDeliveries: false,
            deliveryId: '',
            groupCode: '',
            orderNumber: '',
            products: [],
            totalPrice: '',
            isPaid: '',
            place: '',
            OrderId: '',
            isOrderPaid: false,
            display: "none",
            displayEmailError: 'none',
            approvedPayboxPayment: false,
            errorPhone: "",
            lastNameError: "",
            firstNameError: ""
        }
    }

    componentDidMount() {

        const { match } = this.props;
        window.scrollTo(0, 0);
        document.getElementById('myNav').style.display = 'none'

        // console.log(match.params)

        this.setState({
            deliveryId: match.params.deliveryId,
            groupCode: match.params.groupCode,
            orderNumber: match.params.orderNumber,
        })

        this.setState({ groups: this.props.dataOfGroup })
        this.props.getGroupByCode(match.params.groupCode)

        this.props.getGroupDeliveryOrderByGroupDeliveryIdAndOrderNumber(match.params.deliveryId, match.params.orderNumber).then(() => {
            // console.log('order', this.props.orders)

            this.props.orders.map(order => {
                // console.log('order', order)
                this.setState({
                    firstName: order.PersonInfo.FirstName,
                    lastName: order.PersonInfo.LastName,
                    email: order.PersonInfo.Email,
                    phone: order.PersonInfo.Phone,
                    comment: order.Comment,
                    internalComments: order.InternalComment,
                    paymentChecked: order.PaymentMethod,
                    totalPrice: order.TotalPrice,
                    isPaid: order.IsPaid,
                    OrderId: order._id,
                    isOrderPaid: order.isPaid,
                    approvedPayboxPayment: true
                })

            })

            this.setState({ products: this.props.orders[0].Products })
        })

        this.props.GetGroupDeliveryById(match.params.deliveryId).then(() => {
            this.setState({ isLoadingDeliveries: false })

        })

    }

    updatePaymentStatus = (e, OrderNumber) => {

        const { match } = this.props;

        this.setState({ isPaid: e.target.value })

        this.props.updateGroupOrderPaidStatus(match.params.deliveryId, OrderNumber)

    }

    displayErrorOfEMail = () => {
        this.setState({ displayEmailError: 'block' })
    }

    setUserNote = (val) => {
        this.setState({ comment: val })
    }

    //show the details about the chosen form
    loadGroups = () => this.props.deliveries.map(delivery => {

        document.title = `משק אלמוג - מהחקלאי ל${this.props.groups[0].Name} ${moment(delivery.DeliveryDateTime).format("DD/MM/YYYY")} - עדכון הזמנה`

        if (delivery.IsActive) {

            return (

                <div key={delivery.id} className="container">
                    <div>
                        <div className="card shopping-cart text-center ">
                            <div>
                                <MDBContainer id='formContainer'>
                                    <MDBRow>
                                        <MDBCol md="8" style={{ margin: 'auto' }}>
                                            <form>
                                                <MDBCardImage
                                                    style={{ width: '20%', margin: 'auto' }}
                                                    hover
                                                    overlay='white-light'
                                                    className='card-img-top'
                                                    src={require('../../../../components/img/almog.PNG')}
                                                    alt='man'
                                                />

                                                <h2 className='text-center font-weight-bold'> עריכת הזמנת ירקות ממשק אלמוג ל{this.props.groups[0].Name} 🍅🥒
                                                    <br />  {delivery.Places.length === 0 && delivery.Places[0].Address}
                                                </h2>

                                                <h4 className='text-center font-weight-bold' style={{ color: '#827717' }}>
                                                    יום <GedDayOfWeek dayOfWeek={delivery.DeliveryDateTime} />,&nbsp;
                                                    <GetLocalDayAndHour
                                                        style={{ display: 'inline' }}
                                                        dateOfDelivery={delivery.DeliveryDateTime}
                                                    />
                                                    {delivery.Places.length === 0 &&
                                                        <>
                                                            &nbsp;בשעה&nbsp;
                                                            <GetLocalDayAndHour
                                                                style={{ display: 'inline' }}
                                                                hourOfDelivery={delivery.DeliveryDateTime}
                                                            />
                                                        </>
                                                    }
                                                </h4>
                                                <br />

                                                {
                                                    delivery.Header !== undefined && delivery.Header !== null && delivery.Header !== '' ?
                                                        <div className="col-12 text-center" id="headerOfForm">
                                                            <hr />
                                                            {parse(`${delivery.Header}`)}
                                                            <hr />
                                                        </div>
                                                        : ''
                                                }
                                                <p style={{ textAlign: 'right', color: 'red' }}>* שדה חובה</p>

                                                {

                                                    this.props.orders.map(order => {
                                                        return (
                                                            <div className="black-text" key={order.Id}>
                                                                <MDBInput id='firstName'
                                                                    label="* שם פרטי"
                                                                    valueDefault={order.PersonInfo.FirstName}
                                                                    value={this.state.firstName}
                                                                    onChange={e => {
                                                                        if (e.target.value == "")
                                                                            this.setState({ firstName: "" })
                                                                        else if (validateHebrew(e.target.value)) {
                                                                            return this.setState({
                                                                                firstName: e.target.value,
                                                                                firstNameError: ""
                                                                            })
                                                                        }
                                                                        else
                                                                            this.setState({ firstNameError: "חובה להזין שם משפחה בעברית" })
                                                                    }}
                                                                    required icon="user" group type="text" validate
                                                                    error="wrong" name="user_firstname"
                                                                    success="right"></MDBInput>
                                                                {
                                                                    this.state.firstNameError != "" &&
                                                                    <p className='text-danger' style={{ marginTop: "-30px" }}>
                                                                        אנא מלא/י שם פרטי בעברית
                                                                    </p>
                                                                }

                                                                <MDBInput id='lastName'
                                                                    label="* שם משפחה"
                                                                    valueDefault={order.PersonInfo.LastName}
                                                                    value={this.state.lastName}
                                                                    onChange={e => {
                                                                        if (e.target.value == "")
                                                                            this.setState({ lastName: "" })
                                                                        else if (validateHebrew(e.target.value)) {
                                                                            return this.setState({
                                                                                lastName: e.target.value,
                                                                                lastNameError: ""
                                                                            })
                                                                        }
                                                                        else
                                                                            this.setState({ lastNameError: "חובה להזין שם משפחה בעברית" })
                                                                    }}
                                                                    required icon="user" group type="text" validate
                                                                    error="wrong" name="user_lastname"
                                                                    success="right" />
                                                                {
                                                                    this.state.lastNameError != "" &&
                                                                    <p className='text-danger' style={{ marginTop: "-30px" }}>
                                                                        אנא מלא/י שם משפחה בעברית
                                                                    </p>
                                                                }

                                                                <p className='text-danger' style={{ display: this.state.displayEmailError }}>שגיאה! דואר אלקטרוני לא תקין</p>
                                                                <MDBInput id='email'
                                                                    style={{ marginTop: 0 }}
                                                                    label="* דואר אלקטרוני (לקבלת פירוט ההזמנה למייל)"
                                                                    valueDefault={order.PersonInfo.Email}
                                                                    onChange={e => this.setState({ email: e.target.value })}
                                                                    required icon="envelope" group type="email" validate
                                                                    error="wrong" name="user_email"
                                                                    success="right" />

                                                                <MDBInput id='phone'
                                                                    label="* טלפון נייד"
                                                                    valueDefault={order.PersonInfo.Phone}
                                                                    onChange={e => this.setState({ phone: e.target.value })}
                                                                    onBlur={e => !validatePhone(e.target.value) ? this.setState({ errorPhone: "טלפון לא חוקי" }) : this.setState({ errorPhone: "" })}
                                                                    required icon="phone" group type="tel" validate
                                                                    error="wrong" name="user_phone"
                                                                    maxLength='11'
                                                                    success="right" />

                                                                {
                                                                    this.state.errorPhone != "" &&
                                                                    <p className='text-danger'>{this.state.errorPhone}.
                                                                        <br />
                                                                        אנא מלאו מספר ללא תווים (כגון + -) ובאורך 10 ספרות
                                                                    </p>}

                                                                <h5>* אופן התשלום <i className=" formIcon fas fa-money-bill"></i></h5>
                                                                {
                                                                    !delivery.IsOnlyPaybox ?

                                                                        <select className="form-control" id="paymentMethod" name="paymentMethod"
                                                                            onChange={(e) => this.setState({ paymentChecked: e.target.value })}
                                                                        >
                                                                            {

                                                                                order.PaymentMethod === 'מזומן' ?
                                                                                    <>
                                                                                        <option value={"PayBox"}> PayBox</option>
                                                                                        <option selected={true} value={"מזומן"}>מזומן</option>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <option selected={true} value={"PayBox"}> PayBox</option>
                                                                                        <option value={"מזומן"}> מזומן</option>
                                                                                    </>
                                                                            }
                                                                        </select>
                                                                        :
                                                                        this.state.paymentChecked === 'מזומן' ?
                                                                            <>
                                                                                <label className='myCheckbox'>
                                                                                    <input type='radio'
                                                                                        name='payment'
                                                                                        value='מזומן'
                                                                                        checked={true}
                                                                                        disabled={true}
                                                                                    /> מזומן - לא ניתן לעדכון</label>
                                                                                <br />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <label className='myCheckbox'>
                                                                                    <input type='radio'
                                                                                        name='payment'
                                                                                        value='PayBox'
                                                                                        checked={true}
                                                                                    /> PayBox</label>

                                                                                <br />
                                                                                <br />
                                                                                <div>
                                                                                    <label className='myCheckbox'>
                                                                                        <input type="checkbox"
                                                                                            id='approvedPayboxPaymentChecked'
                                                                                            name="approvedPayboxPayment"
                                                                                            checked={this.state.approvedPayboxPayment}
                                                                                            disabled={true}
                                                                                            onChange={() => {
                                                                                                this.setState(prevState => {
                                                                                                    return {
                                                                                                        approvedPayboxPayment: !prevState.prevState
                                                                                                    }

                                                                                                })

                                                                                                document.getElementById("divOfInputPayment").classList.remove('divOfInputPaymentClass')
                                                                                            }}

                                                                                        />
                                                                                        &nbsp;* אני מאשר/ת העברת תשלום מראש בפייבוקס
                                                                                    </label>
                                                                                </div>
                                                                            </>
                                                                }
                                                                <br />

                                                                {
                                                                    delivery.Places.length > 0 &&
                                                                    <div>
                                                                        <h5>* נקודת חלוקה <i className="formIcon fas fa-map-marker-alt"></i></h5>
                                                                        <select className="form-control" id="place" name="place"
                                                                            onChange={(e) => this.setState({ place: e.target.value })}
                                                                        >
                                                                            {
                                                                                delivery.Places.map((place, index) => {
                                                                                    let oldDateObj = new Date(place.Hour);
                                                                                    let newDateObj = new Date();
                                                                                    newDateObj.setTime(oldDateObj.getTime() + (place.Duration * 60 * 1000));


                                                                                    if (place.Address === order.Place)
                                                                                        return <option selected value={place.Address}>
                                                                                            {moment(place.Hour).tz("Asia/Jerusalem").format('HH:mm')}
                                                                                            -
                                                                                            {moment(newDateObj).tz("Asia/Jerusalem").format('HH:mm')}
                                                                                            &nbsp;&nbsp;{place.Address}
                                                                                        </option>
                                                                                    else
                                                                                        return <option value={place.Address}>
                                                                                            {moment(place.Hour).tz("Asia/Jerusalem").format('HH:mm')}
                                                                                            -
                                                                                            {moment(newDateObj).tz("Asia/Jerusalem").format('HH:mm')}
                                                                                            &nbsp;&nbsp;{place.Address}
                                                                                        </option>

                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                }

                                                                <h3 id='formSecondHeadline' className="h5 text-center mb-"></h3>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </form>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>

                                {/* <hr /> */}
                                <div className="card-header bg-dark text-light">
                                    בחר/י מוצרים&nbsp;
                                    <i className="fa fa-shopping-cart" aria-hidden="true" />
                                    <div className="clearfix" />
                                </div>
                                <br />
                                {
                                    this.state.totalPrice > 0 ?
                                        <>
                                            <UpdateOrderProducts
                                                place={this.state.place != '' ? this.state.place : delivery.Places[0].Address}
                                                deliveryPlaces={delivery.Places}
                                                OrderId={this.state.OrderId}
                                                deliveryDateTime={delivery.DeliveryDateTime}
                                                groupName={this.props.groups[0].Name}
                                                payBoxLink={delivery.PayboxGroupLink}
                                                minimumTotalPrice={delivery.MinimumTotalPrice}
                                                isUserUpdate={true}
                                                productsFromDelivery={delivery.Products}
                                                groupCode={this.state.groupCode}
                                                orderNumber={this.state.orderNumber}
                                                deliveryId={this.state.deliveryId}
                                                firstName={this.state.firstName}
                                                lastName={this.state.lastName}
                                                email={this.state.email}
                                                phone={this.state.phone}
                                                comment={this.state.comment}
                                                internalComments={this.state.internalComments}
                                                paymentChecked={this.state.paymentChecked}
                                                isPaid={this.state.isPaid}
                                                productsFromOrder={this.state.products}
                                                totalPrice={this.state.totalPrice}
                                                footer={parse(`${delivery.Footer}`)}
                                                displayErrorOfEMail={this.displayErrorOfEMail}
                                                setUserNote={this.setUserNote}
                                                errorPhone={this.state.errorPhone}
                                                userNote={this.props.orders && this.props.orders.length > 0 ? this.props.orders[0].Comment : ""}
                                            />
                                        </>
                                        : <h5 className='text-center'>טוען מוצרים...</h5 >
                                }

                            </div>
                        </div>
                    </div>
                </div >
            )

        }
        else {

            return <div style={{ padding: '150px', margin: 'auto' }}>
                <h2 className='text-center text-white font-weight-bold'>הטופס נסגר</h2>
            </div>

        }

    })


    render() {

        return (
            <div className='container-fluid' id='myContainerFluid' >
                <MDBAnimation type="fadeIn" delay=".5s" className="text-center">
                    {
                        this.props.groups[0] != null ?

                            !this.state.isPaid ? this.loadGroups() :
                                <div className="text-center" style={{ height: '350px' }}>
                                    <div style={{ paddingTop: '150px' }}>
                                        <h2 className='text-center text-white'>

                                            הזמנה זו שולמה ולא ניתנת לעדכון.
                                            <br />
                                            <a className='text-white' href={`/groups/${this.state.groupCode}/${this.state.deliveryId}`}>
                                                <b className="font-weight-bold"> ליצירת הזמנה נוספת לחץ/י כאן</b></a>
                                        </h2>
                                    </div>
                                </div>
                            : ''
                    }
                </MDBAnimation>
            </div >
        )
    }

}
const mapStateToProps = (state) => {
    return {
        deliveries: state.deliveriesReducer.deliveries,
        groups: state.groupReducer.groups,
        orders: state.groupOrdersReducer.orders,

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            GetGroupDeliveryById,
            getGroupByCode,
            getGroupDeliveryOrderByGroupDeliveryIdAndOrderNumber,
            updateGroupOrderPaidStatus
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserUpdateOrder);

