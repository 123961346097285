import React, { useState, useEffect } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdbreact';
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { Helmet } from "react-helmet";
import moment from 'moment-timezone'

//components
import FormNav from './FormNav'
import GedDayOfWeek from '../../../components/GedDayOfWeek'
import GetLocalDayAndHour from '../../../components/GetLocalDayAndHour'
import GetHourByDuration from '../../../components/GetHourByDuration'
import FormProducts from './FormProducts'

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GetGroupDeliveryById } from "../../../redux/deliveries/deliveries-actions";

//validations
import { validateHebrew, validatePhone } from '../../../utils/validations';
import MyModal from '../../../components/MyModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const FormFields = (props) => {

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [errorPhone, setErrorPhone] = useState("")
  const [firstNameError, setFirstNameError] = useState("")
  const [lastNameError, setLastNameError] = useState("")
  const [userNote, setUserNote] = useState('')
  const [paymentChecked, setPaymentChecked] = useState('')
  const [approvedPayboxPayment, setApprovedPayboxPayment] = useState(false)
  const [groups, setGroups] = useState([])
  const [isLoadingDeliveries, setIsLoadingDeliveries] = useState(true);
  const [place, setPlace] = useState('');
  const [displayEmailError, setDisplayEmailError] = useState('none');
  const [lastReservationProducts, setLastReservationProducts] = useState([]);
  const [lastReservationText, setLastReservationText] = useState('');
  const [showCopyReservationButton, setShowCopyReservationButton] = useState(true)
  let { groupCode, deliveryId, isAdminForm } = useParams();
  let lastReservation = JSON.parse(localStorage.getItem('lastReservationOfUser'))
  const history = useHistory()

  useEffect(() => {
    setGroups(props.dataOfGroup)

    //show footer after form rendered
    setTimeout(
      function () {
        for (let element of document.getElementsByClassName("footer")) {
          element.style.display = "block";
        }
      }, 1000);
    clearTimeout()

    props.GetGroupDeliveryById(deliveryId).then((data) => {
      // console.log(' props.deliveries', props.deliveries)
      setIsLoadingDeliveries(false)
    })

  }, [deliveryId])

  useEffect(() => {
    if (isAdminForm == 1) {
      setEmail("asafalmog119@gmail.com")
      setPhone("0500000000")
      setPaymentChecked("PayBox")
    }
  }, [isAdminForm])


  const loadLastReservation = (e) => {
    e.preventDefault()
    setShowCopyReservationButton(false)
    if (JSON.parse(localStorage.getItem('lastReservationOfUser')) != null) {
      console.log({ lastReservation })

      const { Email, FirstName, LastName, Phone } = lastReservation.PersonInfo
      setEmail(Email)

      if (!validatePhone(Phone)) {
        setErrorPhone("טלפון לא חוקי")
      }
      else {
        setErrorPhone("")
        setPhone(Phone)
      }

      if (!validateHebrew(FirstName)) {
        setFirstNameError("אנא מלא/י שם פרטי בעברית")
      }
      else {
        setFirstNameError("")
        setFirstName(FirstName)
      }

      if (!validateHebrew(LastName)) {
        setLastNameError("אנא מלא/י שם משפחה בעברית")
      }
      else {
        setLastNameError("")
        setLastName(LastName)
      }

      setPaymentChecked(props.deliveries[0].IsOnlyPaybox ? 'PayBox' : lastReservation.PaymentMethod)
      // setUserNote(lastReservation.Comment)
      setLastReservationProducts(lastReservation.Products)
      setLastReservationText('הזמנה אחרונה הועתקה!')

    }
  }

  //show the details about the chosen form.
  const loadGroups = () => props.deliveries.map(delivery => {
    let isActive = isAdminForm == 1 ? true : delivery.IsActive
    // console.log('delivery', delivery)
    if (isActive) {

      document.getElementById("loadingData").style.display = 'none'

      let checked = null

      let date = <GetLocalDayAndHour
        style={{ display: 'inline' }}
        dateOfDelivery={delivery.DeliveryDateTime}
      />

      return (
        <>

          <Helmet>
            <meta charSet="utf-8" />
            <title>{`משק אלמוג - מהחקלאי ל${groups.Name} ${moment(delivery.DeliveryDateTime).format("DD/MM/YYYY")}`}</title>
            <meta name="description" content={`
          החלוקה תתבצע בתאריך ${date}`} />
          </Helmet >
          <>{console.log(lastReservation)}</>
          {
            props.isThereAnyActiveReservation &&

            <MyModal
              headline={`שלום ${lastReservation.PersonInfo.FirstName}!`}
              headlineText={`ביצעת כבר הזמנה לטופס זה.`}
              headlineBody="האם ברצונך לערוך אותה או לייצר חדשה?"
              show={props.showModal}
              onHide={() => props.setShowModal(false)}
              editreservationtext='עריכת הזמנה'
              newreservationtext='הזמנה חדשה'
              ButtonText=""
              ButtonDinamicFunction={() => history.push(`/groups/update/${groupCode}/${deliveryId}/${lastReservation.orderNumber}`)}
            />
          }

          {
            isAdminForm != 1 &&
            <FormNav
              groupName={groups.Name}
              deliveryAdress={delivery.Places.length === 0 && delivery.Address}
              deliveryDate={
                <h4 className='text-center font-weight-bold'>
                  יום <GedDayOfWeek dayOfWeek={delivery.DeliveryDateTime} />,&nbsp;
                  {date}
                  {delivery.Places.length === 0 &&
                    <>
                      &nbsp;בשעה&nbsp;
                      <GetLocalDayAndHour
                        style={{ display: 'inline' }}
                        hourOfDelivery={delivery.DeliveryDateTime}
                      />
                    </>
                  }
                </h4>
              }
              deliveryPlaces={
                <div id='formPlaces'>
                  {
                    delivery.Places.map((place, index) => {

                      if (delivery.Places.length === 1) {
                        return <p key={index}>
                          <span className='text-center' style={{ paddingRight: '0' }}>
                            &nbsp;&nbsp;<span id="formDurationTime" className="font-italic">
                              {moment(place.Hour).tz("Asia/Jerusalem").format('HH:mm')}
                              {/* {new Date(place.Hour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                              -{<GetHourByDuration
                                hour={place.Hour}
                                duration={place.Duration}
                              />}</span>
                          </span><b> {place.Address}</b></p>
                      }
                      else {
                        return <li key={index}>
                          <p style={{ display: 'inline' }}>
                            <span >
                              &nbsp;&nbsp;<span id="formDurationTime" className="font-italic">
                                {/* {new Date(place.Hour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                {moment(place.Hour).tz("Asia/Jerusalem").format('HH:mm')}
                                -{<GetHourByDuration
                                  hour={place.Hour}
                                  duration={place.Duration}
                                />}</span>
                            </span>
                            <b> {place.Address}</b>
                          </p>
                        </li>
                      }

                    })
                  }
                </div>
              }
            />
          }

          <div key={delivery.id}>
            <div className="container">
              {/* <h2 className='text-center font-weight-bold'>הזמנה</h2> */}
              <div className="card shopping-cart text-center " style={{ margin: '0' }}>
                <div>
                  <MDBContainer id='formContainer'>
                    <MDBRow>
                      {
                        isAdminForm != 1 ?
                          <>

                            {
                              delivery.Header !== undefined && delivery.Header !== null && delivery.Header !== '' ?
                                <div className="col-12 text-center" id="headerOfForm">
                                  {parse(`${delivery.Header}`)}
                                  {/* <hr style={{ background: '2px solid' }} /> */}
                                </div>
                                : ''
                            }
                          </>
                          : <h2 className='text-center m-auto pt-2 pb-2'>טופס רישום פנימי</h2>
                      }
                      {
                        delivery.Header !== undefined && delivery.Header !== null && delivery.Header !== '' ?
                          <div className='card-header bg-dark text-light second-template'> אנא מלא/י את הפרטים הבאים</div>
                          : ''
                      }

                      <MDBCol size="12" md="8" style={{ margin: 'auto' }}>

                        <form>

                          {
                            delivery.Header !== undefined && delivery.Header !== null && delivery.Header !== '' ?
                              ""
                              : <h3 id='formHeadline' className="h5 text-center mb-4">אנא מלא/י את הפרטים הבאים</h3>

                          }
                          {
                            lastReservation != null && isAdminForm != 1 && showCopyReservationButton &&
                            <MDBBtn rounded outline color="secondary"
                              onClick={(e) => loadLastReservation(e)}
                            ><i className="far fa-copy fa-x"></i>&nbsp;
                              העתקת הזמנה אחרונה
                            </MDBBtn>
                          }
                          <p>{lastReservationText}</p>

                          <p style={{ textAlign: 'right', color: 'red' }}>* שדה חובה</p>
                          <div className="black-text">

                            <MDBInput id='firstName' label="* שם פרטי" value={firstName} onChange={e => {
                              if (e.target.value == "")
                                setFirstName("")
                              else if (validateHebrew(e.target.value)) {
                                setFirstName(e.target.value)
                                setFirstNameError("")
                              }
                              else
                                setFirstNameError("חובה להזין שם בעברית")
                            }}
                              // onBlur={e => !validateHebrew(e.target.value) ? setFirstNameError("חובה להזין שם בעברית") : setFirstNameError("")}
                              required icon="user" group type="text" validate
                              error="wrong" name="user_firstname"
                              success="right"
                              autocomplete="off"
                            />
                            {
                              firstNameError != "" &&
                              <p className='text-danger' style={{ marginTop: "-30px" }}>
                                אנא מלא/י שם פרטי בעברית
                              </p>
                            }
                            <MDBInput id='lastName' label="* שם משפחה" value={lastName} onChange={e => {
                              if (e.target.value == "")
                                setLastName("")
                              else if (validateHebrew(e.target.value)) {
                                setLastName(e.target.value)
                                setLastNameError("")
                              }
                              else
                                setLastNameError("חובה להזין שם משפחה בעברית")
                            }}
                              // onBlur={e => !validateHebrew(e.target.value) ? setLastNameError(" אנא מלא/י שם משפחה בעברית") : setLastNameError("")}
                              required icon="user" group type="text" validate
                              error="wrong" name="user_lastname"
                              success="right"
                              autocomplete="off"
                            />
                            {
                              lastNameError != "" &&
                              <p className='text-danger' style={{ marginTop: "-30px" }}>
                                אנא מלא/י שם משפחה בעברית
                              </p>
                            }
                            <p className='text-danger' style={{ display: displayEmailError }}> שגיאה! דואר אלקטרוני לא תקין</p>
                            {
                              isAdminForm != 1 &&
                              <MDBInput id='email' label="* דואר אלקטרוני (לקבלת ההזמנה למייל)"
                                style={{ marginTop: 0 }}
                                value={email} onChange={e => setEmail(e.target.value)}
                                required icon="envelope" group type="email"
                                error="wrong" name="user_email" validate
                                success="right" />
                            }
                            {
                              isAdminForm != 1 &&
                              <MDBInput id='phone' label="* טלפון נייד" value={phone} onChange={e => setPhone(e.target.value)}
                                onBlur={e => !validatePhone(e.target.value) ? setErrorPhone("טלפון לא חוקי") : setErrorPhone("")}
                                required icon="phone" group type="tel" validate
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                error="wrong" name="user_phone"
                                maxLength='11'
                                success="right" />
                            }
                            {
                              errorPhone != "" &&
                              <p className='text-danger'>{errorPhone}.
                                <br />
                                אנא מלאו מספר ללא תווים (כגון + -) ובאורך 10 ספרות
                              </p>
                            }

                            {

                              delivery.Places.length > 0 &&
                              <div id="divOfInputPlaces">
                                <h5>* נקודת חלוקה <i className="formIcon fas fa-map-marker-alt"></i></h5>
                                {
                                  delivery.Places.map((place, index) => {
                                    if (delivery.Places.length === 1) {
                                      checked = true
                                    }

                                    let hourByDuration = <GetHourByDuration
                                      hour={place.Hour}
                                      duration={place.Duration}
                                    />
                                    return <>
                                      <label className="myCheckbox">
                                        <input key={index}
                                          id='place'
                                          type='radio'
                                          name='place'
                                          checked={checked}
                                          value={place.Address}
                                          onChange={() => {
                                            document.getElementById("divOfInputPlaces").classList.remove('divOfInputPlacesClass')
                                            setPlace(place.Address)
                                          }
                                          }
                                        />&nbsp;
                                        {/* {new Date(place.Hour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                        {moment(place.Hour).tz("Asia/Jerusalem").format('HH:mm')}
                                        -{hourByDuration}&nbsp;&nbsp;
                                        {place.Address}
                                      </label>
                                      <br />
                                    </>
                                  })
                                }

                              </div>
                            }
                            <br />
                            <div id="divOfInputPayment">

                              <h5>* אופן התשלום <i className=" formIcon fas fa-money-bill"></i></h5>
                              {/* <input type='radio'></input> */}
                              {
                                !delivery.IsOnlyPaybox ?
                                  <>
                                    <label className='myCheckbox'>
                                      <input
                                        id='payment'
                                        type='radio'
                                        name='payment'
                                        value='מזומן'
                                        checked={paymentChecked === 'מזומן'}
                                        onChange={() => {
                                          document.getElementById("divOfInputPayment").classList.remove('divOfInputPaymentClass')
                                          setPaymentChecked('מזומן')
                                        }}
                                      /> מזומן</label>
                                    <br />
                                    <label className='myCheckbox'>
                                      <input type='radio'
                                        name='payment'
                                        value='PayBox'
                                        checked={paymentChecked === 'PayBox'}
                                        onChange={() => {
                                          document.getElementById("divOfInputPayment").classList.remove('divOfInputPaymentClass')
                                          setPaymentChecked('PayBox')
                                        }}
                                      /> PayBox</label>
                                  </>
                                  :
                                  <>

                                    <label className='myCheckbox'>
                                      <input type='radio'
                                        name='payment'
                                        value='PayBox'
                                        checked={true}
                                      /> PayBox</label>
                                    <br />
                                    <br />
                                    <div>
                                      <label className='myCheckbox'>
                                        <input type="checkbox"
                                          id='approvedPayboxPaymentChecked'
                                          name="approvedPayboxPayment"
                                          checked={approvedPayboxPayment}
                                          value={approvedPayboxPayment}
                                          onChange={() => {
                                            setApprovedPayboxPayment(prev => !prev)
                                            document.getElementById("divOfInputPayment").classList.remove('divOfInputPaymentClass')
                                          }}

                                        />
                                        &nbsp;* אני מאשר/ת העברת תשלום מראש בפייבוקס
                                      </label>
                                    </div>
                                  </>

                              }
                            </div>

                            <h3 id='formSecondHeadline' className="h5 text-center mb-4"></h3>
                          </div>

                        </form>
                      </MDBCol>
                    </MDBRow>
                    <div id='Reservation'>


                    </div>
                  </MDBContainer>
                  <div className="card-header bg-dark text-light second-template">
                    בחר/י מוצרים&nbsp;
                    <i className="fa fa-shopping-cart" aria-hidden="true" />
                    <div className="clearfix" />
                  </div>
                  <br />
                  <FormProducts
                    // InActiveProductsFromDelivery={delivery.InActiveProducts}
                    groupName={groups.Name}
                    payBoxLink={delivery.PayboxGroupLink}
                    deliveryPlaces={delivery.Places}
                    place={place != '' ? place :
                      delivery.Places.length === 1 ?
                        delivery.Places[0].Address
                        : ''
                    }
                    deliveryDateTime={delivery.DeliveryDateTime}
                    deliveryAddress={delivery.Address}
                    productsFromDelivery={delivery.Products}
                    minimumTotalPrice={delivery.MinimumTotalPrice}
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    phone={phone}
                    userNote={userNote}
                    paymentChecked={delivery.IsOnlyPaybox ? 'PayBox' : paymentChecked}
                    approvedPayboxPayment={delivery.IsOnlyPaybox ? approvedPayboxPayment : true}
                    footer={parse(`${delivery.Footer}`)}
                    setDisplayEmailError={setDisplayEmailError}
                    lastReservationProducts={lastReservationProducts}
                    errorPhone={errorPhone}
                    isAdminForm={isAdminForm == 1 ? true : false}
                    setUserNote={setUserNote}
                  />
                </div>
              </div>
            </div>
          </div >
        </>
      )

    }

    else {
      document.getElementById("loadingDataHeadline").innerText = 'הטופס נסגר'
      document.getElementById("loadingData").style.display = 'block'
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`משק אלמוג - מהחקלאי ל${groups.Name} ${moment(delivery.DeliveryDateTime).format("DD/MM/YYYY")}`}</title>
          <meta name="description" content={`
            ב -
            ${<GetLocalDayAndHour
              style={{ display: 'inline' }}
              dateOfDelivery={delivery.DeliveryDateTime}
            />}
            `} />
        </Helmet >
      )
    }


  })

  return (
    <div id='myContainerFluid'>

      {
        !isLoadingDeliveries ? loadGroups() : ''
      }
      <div style={{ padding: '160px', margin: 'auto' }} id='loadingData'>
        <h2 id="loadingDataHeadline" className='text-center text-white font-weight-bold'>טוען...</h2>

      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    deliveries: state.deliveriesReducer.deliveries
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      GetGroupDeliveryById
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormFields);

